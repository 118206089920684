/*!
 * morpher site globals
 */
 
 body {
    background-color: #000;
    padding: 0px;
    margin:0 auto; 
    color: #fff; 
    overflow-x: hidden;
}

.gradientfx {
	background: linear-gradient(-45deg, #11161e, #000, #0c0c0c, #2f5168);
	background-size: 400% 400%;
	animation: gradient 25s ease infinite; 
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

#wrapper { 
    padding-top: 80px;
}

:root {
--nav-color: rgb(182, 156, 156);
--nav-hover-color: rgb(217, 219, 223);
}
  
a {
    text-decoration: none;
}

p, h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
}

p {
  font-size: 13px;
}

h1 {
  font-size: 22px;
}

p a {
    color: var(--nav-color);
}

p a:hover {
    color: var(--nav-hover-color);
}

.link {
  color: var(--nav-hover-color);
}

.link:hover {
  color: #fff;
}

.text-center {
    text-align: center;
}

html {
    scroll-behavior: smooth;
}

.grid-line {
      position: fixed;
    height: 100vh;
    border: 1px dotted rgba(255,255,255,0.05);
}

.ln1 {
    left: 10%;
}
.ln2 {
    left: 40%;
}
.ln3 {
    left: 60%;
}
.ln4 {
    left: 90%;
}

.section {
    min-height: 50em;
    padding: 4em;  
}

.section .content {
    padding: 2em;
    max-width: 800px;
    margin: 0 auto; 
}

.section .content-full {
    padding: 2em;
    max-width: 1800px;
    margin: 0 auto; 
}

.logo {
    width: 100%;
    max-width: 600px;
    height: 300px;
    margin:auto;
    background-image: url(../img/morpherLogo.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position:center center; 
}
  
.mrp-canvas {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%); 
  z-index: -1;
  opacity: 0.7;
}

.mrp {
	fill: none;
	stroke: rgb(107, 107, 107);
	stroke-width: 0.5;
	transform-origin: center center;
	animation: mrp-animation 15s linear infinite;
}

.mrp-1 {
	animation-delay: 0s;
}
.mrp-2 {
	animation-delay: -0.5s;
}
.mrp-3 {
	animation-delay: -1s;
    opacity: 0.3;
}
.mrp-4 {
	animation-delay: -1.5s;
}
.mrp-5 {
	animation-delay: -2s;
    stroke: rgb(172, 172, 172); 
}
.mrp-6 {
	animation-delay: -2.5s;
}
.mrp-7 {
	animation-delay: -3s;
}
.mrp-8 {
	animation-delay: -3.5s;
}
.mrp-9 {
	animation-delay: -4;
    stroke: rgb(54, 54, 54); 
}
.mrp-10 {
	animation-delay: -4.5s;
    color: #000;
}
.mrp-11 {
	animation-delay: -5s;
    color: red;
}
.mrp-12 {
	animation-delay: -5.5s;
}
.mrp-13 {
	animation-delay: -6s;
    stroke: rgb(204, 204, 204); 
}
.mrp-14 {
	animation-delay: -6.5s;
}
.mrp-15 {
	animation-delay: -7s;
    stroke: rgb(102, 102, 102);
}
.mrp-16 {
	animation-delay: -7.5s;
}
.mrp-17 {
	animation-delay: -8s; 
    color: #000;
}
.mrp-18 {
	animation-delay: -8.5s;
}
.mrp-19 {
	animation-delay: -9s; 
}
.mrp-20 {
	animation-delay: -9.5s;
}

@keyframes mrp-animation{
	0%{
		transform: scale(0) rotate(0deg);
		opacity: 1;
	}
	
	100%{
		transform: scale(3) rotate(45deg);
		opacity: 0;
	}
}
 
 
@media screen and (max-width: 50em) {
	.grid figure {
		display: inline-block;
		float: none;
		margin: 10px auto;
		width: 100%;
	}
} 

@media only screen and (max-width: 1800px) {
  .menuitems {
      max-width: 940px;
  }
}

@media only screen and (max-width: 1600px) {
  .menuitems {
      max-width: 440px;
  }
}


.sciframe { 
  height: 455px;
}

.sciframe iframe { 
  height: 400px;
}

.youtubeimg {
  margin-bottom: 20px;
}
.youtubeimg:hover img {
  opacity: 0.7;
}

.social {
  display: inline-block;
  max-width: 60px;
}



@media only screen and (max-width: 800px) {
 
  .sciframe { 
    height: 310px;
  }
  
  .sciframe iframe { 
    height: 300px;
  }

  .section {
    min-height: 20em;
    padding: 1em;  
}

  .section .content {
      padding: 1em; 
  }

  .section .content-full {
      padding: 1em; 
  }

  .mrp-canvas {
    display: none;
  }

  .logo { 
    max-width: 400px; 
    height: 150px;
  }

  .social { 
    max-width: 40px;
  }
  
  .social .fa-2x {
    font-size: 1.5em;
  }

}