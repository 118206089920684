/*!
 * morpher menu
 */
 
 :root {
  --nav-color: rgb(182, 156, 156);
  --nav-hover-color: rgb(217, 219, 223);
  }

  #mainmenu { 
    width: 100%;
    z-index: 20;
    position: fixed;   
    background-color: rgb(0, 0, 0);
    border-bottom: 1px dotted #ffffff2b;
}

@media only screen and (max-width: 768px) {
  .menuitems {
      display: none;
  }
}

.menuitems {
  margin: 0 auto;
  padding: 6px;
  margin: 0 auto; 
  max-width: 1600px;
}

.menuitems_container {
  display: inline-block;
  float: right; 
  margin-right: 90px;
}
  
.menu_logo {
  padding-top: 65px;
  margin-left: 35px; 
  width: 150px;
  display: inline-block;
  background-image: url(../img/morpherLogo.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0;
  transition: all 0.5s ease;
}

.showlogo {
  opacity: 1;
}

a.navitem {
  padding: 26px; 
  display: inline-block;
}

.navitem {
  color: #fff;
}

.navitem:hover {
  color: rgb(224, 224, 224) !important;
}

a.navitem:hover {
  padding: 26px;
  color: var(--nav-color-hover);
} 

@keyframes bugfix {
    from {
      padding: 0;
    }
    to {
      padding: 0;
    }
  }
  @-webkit-keyframes bugfix {
    from {
      padding: 0;
    }
    to {
      padding: 0;
    }
  }
  #menu-overlay-button {
    position: absolute;
    right: 20px;
    top: 13px;
    padding: 26px 11px; 
    cursor: pointer;
    user-select: none;
    z-index: 222;
  }
  #menu-overlay-button span {
    height: 3px;
    width: 35px;
    border-radius: 2px;
    background-color: white;
    position: relative;
    display: block;
    transition: all 0.2s ease-in-out;
  }
  #menu-overlay-button span:before {
    top: -10px;
    visibility: visible;
  }
  #menu-overlay-button span:after {
    top: 10px;
  }
  #menu-overlay-button span:before, #menu-overlay-button span:after {
    height: 3px;
    width: 35px;
    border-radius: 2px;
    background-color: white;
    position: absolute;
    content: "";
    transition: all 0.2s ease-in-out;
  }
  #menu-overlay-button:hover span, #menu-overlay-button:hover span:before, #menu-overlay-button:hover span:after {
    background: #fff;
  }
    
  #menu-overlay-button.active:hover span,  #menu-overlay-button.active span {
    background: transparent;
  }

  #menu-overlay-button.active span:before {
    transform: rotate(45deg) translate(7px, 7px);
    opacity: 1;
  }
 
  #menu-overlay-button.active span:after {
    transform: rotate(-45deg) translate(7px, -7px);
  }

  /* body.menu-active {
    overflow: hidden;
  } */
  
  #menu-overlay {
    height: 100vh;
    width: 100vw;
    background: #11161e;
    z-index: 99;
      visibility: hidden;  
    position: fixed; 
  }
  #menu-overlay.active {
     visibility: visible;  
  }
  #menu-overlay ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 100vh;
    padding-left: 0;
    list-style-type: none;
  }
  #menu-overlay ul li {
    padding: 1em;
  }
  #menu-overlay ul li a {
    color: white;
    text-decoration: none;
    font-size: 1.5em;
  }
  #menu-overlay ul li a:hover {
    color: var(--nav-hover-color);
  } 